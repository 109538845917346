import { replace } from 'connected-react-router';
import { useAtom, useSetAtom } from 'jotai';
import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { sendTokenLogin } from '../../actions/auth';
import { setOnboardingStatus } from '../../actions/signup';
import { Routes } from '../../components/Buttons';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';
import { InlineDialog } from '../../components/Dialogs/InlineDialog';
import { GenderValues, MetricsEvents } from '../../types/enums';
import { useIsFirstRender } from '../../utils/useIsFirstRender';
import useLogEventFirstRender from '../../utils/useLogEventFirstRender';
import {
  mobileStylizedAvatarIdAtom,
  replikaPronounsAtom,
  stylizedAvatarIdAtom,
  tokenLoginAtom,
} from './atoms';
import {
  defaultStylizedAvatarId,
  femaleAvatars,
  realisticAvatarsToStylized,
} from './consts';

type TokenLoginProps = {
  className?: string;
};

export const TokenLogin = memo(({ className }: TokenLoginProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isFirstRender = useIsFirstRender();

  const [{ error }, setData] = useAtom(tokenLoginAtom);
  const setStylizedAvatarId = useSetAtom(stylizedAvatarIdAtom);
  const setMobileStylizedAvatarId = useSetAtom(mobileStylizedAvatarIdAtom);
  const setReplikaPronouns = useSetAtom(replikaPronounsAtom);

  const searchParams = new URLSearchParams(location.search);
  const utm_source = searchParams.get('utm_source');
  const utm_campaign = searchParams.get('utm_campaign');
  const single_use_token = searchParams.get('single_use_token');
  const onboardingAvatarName = decodeURIComponent(
    searchParams.get('onboarding_avatar_name') || defaultStylizedAvatarId,
  );

  const redirectToLogin = () => dispatch(replace(Routes.Login));
  const setError = (error: unknown) => {
    setData((prev) => ({
      ...prev,
      error,
    }));
  };

  const handleLogin = async () => {
    if (!single_use_token) {
      redirectToLogin();
      return;
    }

    setData((prev) => ({
      ...prev,
      isLoading: true,
    }));

    try {
      await dispatch(sendTokenLogin(single_use_token));

      dispatch(setOnboardingStatus('posttokenlogin'));
      dispatch(replace(Routes.SignupYourPassword));
    } catch (error) {
      setError(error);
    } finally {
      setData((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  const handleConfirm = () => {
    setData((prev) => ({
      ...prev,
      error: null,
    }));

    handleLogin();
  };

  const handleCancel = () => {
    redirectToLogin();
  };

  useEffect(() => {
    if (isFirstRender) {
      setData((prev) => ({
        ...prev,
        params: searchParams,
      }));
      // We don't support realistic avatars yet.
      const avatarId =
        realisticAvatarsToStylized[onboardingAvatarName] ||
        onboardingAvatarName;
      setStylizedAvatarId(avatarId);
      setMobileStylizedAvatarId(onboardingAvatarName);
      setReplikaPronouns(
        femaleAvatars[avatarId] ? GenderValues.Female : GenderValues.Male,
      );

      handleLogin();
    }
    // eslint-disable-next-line local-rules/exhaustive-deps
  }, [dispatch, isFirstRender, single_use_token]);

  useLogEventFirstRender(MetricsEvents.LoginScreenOpened, {
    from: `${utm_source} - ${utm_campaign}`,
  });

  return (
    <InlineDialog isActive={!!error}>
      <ConfirmationDialog
        content={{
          title: 'Something went wrong',
          description: `Try again or use your email to log in. If using email, ensure it matches and follow the 'Forgot your password' steps.`,
          secondaryText: 'Try again',
          primaryText: 'Log in with email',
        }}
        onClose={() => {
          setError(null);
        }}
        onSecondaryClick={handleConfirm}
        onPrimaryClick={handleCancel}
      />
    </InlineDialog>
  );
});
