import swrFetcher from 'src/core/api/swrFetcher';
import { API_BASE_URL } from 'src/utils/uri';
import useSWR from 'swr';

export const useSubscriptionToken = () => {
  return useSWR(
    `${API_BASE_URL}/payment_subscriptions/web_auth_token`,
    (url) => {
      return swrFetcher<{
        web_url: string;
      }>(url, { method: 'POST' }).then(({ web_url }) => {
        if (web_url) {
          const url = new URL(web_url);
          return { search: url.search, webUrl: web_url };
        }
        return null;
      });
    },
  );
};
