import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getPersonalBotChat } from '../../actions/chat';
import { getPersonalBot, getUserProfile } from '../../actions/profile';
import { completeSignup } from '../../actions/signup';
import { getSubscriptions } from '../../actions/subscriptions';
import { GenderValues } from '../../types/enums';
import {
  CustomizationVariationItem,
  TAvatarGeneration,
} from '../../types/models';

type SignupOpts = {
  replikaName?: string;
  replikaGender?: GenderValues;
  avatarId: string;
  activeVariations: CustomizationVariationItem[];
  avatarGeneration?: TAvatarGeneration;
  interests: string[];
};

export default function useCompleteSignup() {
  const dispatch = useDispatch();

  return useCallback(
    async ({
      replikaName,
      replikaGender,
      avatarId,
      activeVariations,
      avatarGeneration,
      interests,
    }: SignupOpts) => {
      await dispatch(
        completeSignup(
          {
            replikaName,
            replikaGender,
            avatarId,
            activeVariations,
            avatarGeneration,
          },
          interests,
        ),
      );

      // we can fetch these in parallel
      return await Promise.all([
        dispatch(getUserProfile()),
        dispatch(getPersonalBotChat()),
        dispatch(getPersonalBot()),
        dispatch(getSubscriptions()),
      ]);
    },
    [dispatch],
  );
}
