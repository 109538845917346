import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { getAvatars } from '../../actions/avatars';
import { currentAvatarAtom } from '../../core/atoms';
import useApi from '../../utils/useApi';
import {
  Customization,
  avatarIdAtom,
  customizationAtom,
  pronounsAtom,
} from './atoms';

export default function useUpdateDefaultModel() {
  const setAvatarId = useSetAtom(avatarIdAtom);
  const setAvatar = useSetAtom(currentAvatarAtom);
  const setCustomization = useSetAtom(customizationAtom);
  const pronouns = useAtomValue(pronounsAtom);

  const getGenderedAvatars = useCallback(() => {
    return getAvatars(pronouns);
  }, [pronouns]);

  const models = useApi((state) => state.avatars.modelsV2, getGenderedAvatars, {
    memoDeepEqual: true,
  });

  return useCallback(() => {
    if (!models.length) return;

    const defModel = models[0];

    if (defModel) {
      setAvatarId(defModel.id);
      setAvatar({
        type: defModel.avatar_type,
        variations: defModel.active_variations,
        age: defModel.age,
        bodyType: defModel.body_type ?? null,
        gender: defModel.gender,
        roomItems: null,
      });
      let customization: Customization = { hair: null, eye: null, skin: null };
      for (let v of defModel.active_variations) {
        let tab =
          v.unity_category === 'style'
            ? 'skin'
            : v.unity_category === 'eyes'
              ? 'eye'
              : v.unity_category;
        customization[tab] = v;
      }
      setCustomization(customization);
    }
  }, [models]);
}
