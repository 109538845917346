import { replace } from 'connected-react-router';
import { useInputField } from 'form-atoms';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Progress } from 'src/types/states';
import styled from 'styled-components/macro';
import { getPersonalBot, updateBot } from '../../actions/profile';
import { setOnboardingStatus } from '../../actions/signup';
import { Routes } from '../../components/Buttons';
import { LabeledTextInput } from '../../components/Inputs';
import RadioButtonSelector, {
  DarkRadioButton,
} from '../../components/RadioButtonSelector';
import { avatarStateAtom, currentAvatarAtom } from '../../core/atoms';
import useAvatarSettings from '../../core/useAvatarSettings';
import { GenderValues, Genders, MetricsEvents } from '../../types/enums';
import { ApiError } from '../../utils/apiError';
import { captureError } from '../../utils/initSentry';
import { setUserProperties } from '../../utils/metrics';
import useLogEvent from '../../utils/useLogEvent';
import useLogEventFirstRender from '../../utils/useLogEventFirstRender';
import useSubmitStatus from '../../utils/useSubmitStatus';
import { AuthFormWithAvatar, DesktopTitle, SubmitError } from './AuthLayout';
import { OnboardingSubmitButton } from './SignupYourNameAndPronouns';
import {
  Customization,
  avatarIdAtom,
  customizationAtom,
  interestsAtom,
  isSubmitDisabled,
  replikaNameAtom,
  replikaPronounsAtom,
  stylizedAvatarIdAtom,
} from './atoms';
import { femaleAvatars } from './consts';
import { useOnboardingAvatarMutation } from './queries';
import useCompleteSignup from './useCompleteSignup';

const GENDER_OPTS_MALE = [
  { label: Genders.NonBinary, value: GenderValues.NonBinary },
  { label: Genders.Male, value: GenderValues.Male },
  { label: Genders.Female, value: GenderValues.Female },
];

const GENDER_OPTS_FEMALE = [
  { label: Genders.NonBinary, value: GenderValues.NonBinary },
  { label: Genders.Female, value: GenderValues.Female },
  { label: Genders.Male, value: GenderValues.Male },
];

export default function SignupReplikaNameAndPronouns() {
  const dispatch = useDispatch();
  const { trigger } = useOnboardingAvatarMutation();
  const { bot, getPersonalBotProgress } = useSelector((state) => ({
    bot: state.profile.persist.bot,
    getPersonalBotProgress: state.profile.getPersonalBotProgress,
  }));

  const onboardingStatus = useSelector(
    (state) => state.signup.persist.onboardingStatus,
  );

  useLogEventFirstRender(MetricsEvents.OnboardingReplikaNameScreen);

  const replikaNameField = useInputField<'text'>(replikaNameAtom);

  const [replikaPronouns, setReplikaPronouns] = useAtom(replikaPronounsAtom);

  const setAvatarId = useSetAtom(avatarIdAtom);
  const interests = useAtomValue(interestsAtom);

  const setAvatar = useSetAtom(currentAvatarAtom);
  const setCustomization = useSetAtom(customizationAtom);
  const stylizedAvatarId = useAtomValue(stylizedAvatarIdAtom);

  const submitStatus = useSubmitStatus();

  const logEvent = useLogEvent();

  const avatarState = useAtomValue(avatarStateAtom);

  const completeSignup = useCompleteSignup();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      submitStatus.setStatus('submitting');

      const newAvatarGeneration = bot?.avatar_generation === 'v3' ? 'v3' : 'v2';
      const avatar_v2 =
        bot?.avatar_v2?.id && bot?.avatar_generation === 'v3'
          ? bot?.avatar_v2
          : (await trigger(stylizedAvatarId))?.avatar_v2;

      if (!avatar_v2?.id) return;

      setAvatarId(avatar_v2.id);
      setAvatar({
        type: avatar_v2.avatar_type,
        age: avatar_v2.age,
        variations: avatar_v2.active_variations,
        bodyType: avatar_v2.body_type ?? null,
        gender: avatar_v2.gender,
        roomItems: null,
      });
      let customization: Customization = {
        hair: null,
        eye: null,
        skin: null,
      };
      for (let v of avatar_v2.active_variations) {
        let tab = v.unity_category === 'style' ? 'skin' : v.unity_category;
        customization[tab] = v;
      }
      setCustomization(customization);

      if (avatarState !== '3d') {
        await completeSignup({
          avatarId: avatar_v2.id,
          activeVariations: avatar_v2.active_variations ?? [],
          avatarGeneration: newAvatarGeneration,
          replikaName: replikaNameField.props.value,
          replikaGender: replikaPronouns,
          interests,
        });

        submitStatus.setStatus('success');

        if (onboardingStatus === 'posttokenlogin') {
          dispatch(replace(Routes.SignupChoosePlatform));
        } else {
          dispatch(replace(Routes.SignupSubscription));
          dispatch(setOnboardingStatus('subscription'));
        }

        setUserProperties({
          style: stylizedAvatarId,
          'replika gender': replikaPronouns,
        });
      } else {
        await dispatch(
          updateBot({
            name: replikaNameField.props.value,
            gender: replikaPronouns,
            avatar_generation: newAvatarGeneration,
          }),
        );

        submitStatus.setStatus('success');

        dispatch(replace(Routes.SignupUniquelyYours));
      }

      // legacy
      logEvent(MetricsEvents.ReplikaNameAdded, {
        'replika name': replikaNameField.props.value,
      });
      logEvent(MetricsEvents.ReplikaNameAndGenderSet, {
        'replika name': replikaNameField.props.value,
        'replika gender': replikaPronouns,
      });
    } catch (e) {
      let error = e;

      if (e instanceof ApiError) {
        logEvent(MetricsEvents.ReplikaNameError, { error: e.technicalMessage });

        if ((e.code === 1 || e.code === 11) && e.message) {
          replikaNameField.actions.setErrors([e.message]);
          error = null;
        }
      }

      submitStatus.setStatus('error', error as Error);
      captureError(e);
    }
  };

  useEffect(() => {
    if (
      !bot &&
      (getPersonalBotProgress === Progress.initial ||
        getPersonalBotProgress === Progress.success)
    ) {
      dispatch(getPersonalBot());
    }
  }, [bot, getPersonalBotProgress]);

  useAvatarSettings({
    hidden: false,
    background: 'onboarding',
    cameraSlot: 'desktop_onboarding',
    baseBundleSet: 'onboarding',
  });

  const submitDisabled =
    isSubmitDisabled(replikaNameField) ||
    getPersonalBotProgress === Progress.sending;

  const variants = !!femaleAvatars[stylizedAvatarId]
    ? GENDER_OPTS_FEMALE
    : GENDER_OPTS_MALE;

  return (
    <AuthFormWithAvatar onSubmit={handleSubmit}>
      <DesktopTitle>Replika's name & gender</DesktopTitle>
      <Input
        id="signup-name"
        placeholder="Name"
        autoComplete="name"
        errors={replikaNameField.state.errors}
        autoFocus
        {...replikaNameField.props}
      />

      <PronounsSelector
        variants={variants}
        layout="horizontal"
        value={replikaPronouns}
        id="signup-replika-pronouns"
        onChange={(p) => setReplikaPronouns(p)}
        buttonTheme="darkSolid"
      />
      <Error error={submitStatus.getError()} placeholder={<></>} />
      <OnboardingSubmitButton
        disabled={submitDisabled}
        showSpinner={submitStatus.isSubmitting()}
      >
        Continue
      </OnboardingSubmitButton>
    </AuthFormWithAvatar>
  );
}

const Input = styled(LabeledTextInput)`
  width: 100%;
  @media ${(p) => p.theme.breakpoints.tablet} {
    max-width: 350px;
  }
`;

const PronounsSelector = styled(RadioButtonSelector)`
  padding: 0;
  margin: 0 auto;
  width: 100%;
  flex-direction: row;
  gap: 5px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    max-width: 350px;
    & > button {
      flex-basis: 0%;
    }
  }

  ${DarkRadioButton} {
    border-radius: 19px;
    font-size: 14px;
    line-height: 18px;
    height: 44px;
    padding: 0 10px;
  }
` as typeof RadioButtonSelector;

const Error = styled(SubmitError)`
  width: 100%;
  max-width: 420px;
`;
