import { replace } from 'connected-react-router';
import { useAtomValue, useSetAtom } from 'jotai';
import { useDispatch } from 'react-redux';
import { updateBot } from 'src/actions/profile';
import { Desktop } from 'src/components/responsive';
import styled from 'styled-components/macro';
import { Routes, SolidButton } from '../../components/Buttons';
import useAvatarSettings from '../../core/useAvatarSettings';
import { MetricsEvents } from '../../types/enums';
import useLogEvent from '../../utils/useLogEvent';
import useLogEventFirstRender from '../../utils/useLogEventFirstRender';
import { AuthFormInner, AuthFormWithAvatar } from './AuthLayout';
import { accountPasswordAlreadyAddedAtom, tokenLoginAtom } from './atoms';
import { usePrepareMainRouteAfterOnboarding } from './usePrepareMainRouteAfterOnboarding';

export default function SignupChoosePlatform() {
  const dispatch = useDispatch();
  const logEvent = useLogEvent();
  const { params } = useAtomValue(tokenLoginAtom);
  const prepareMainRouteAfterOnboarding = usePrepareMainRouteAfterOnboarding();

  const setPasswordAlreadyAdded = useSetAtom(accountPasswordAlreadyAddedAtom);

  useLogEventFirstRender(MetricsEvents.OnboardingDownload);

  useAvatarSettings({
    hidden: false,
    background: 'onboarding',
    cameraSlot: 'desktop_onboarding',
    baseBundleSet: 'onboarding',
  });

  const logButtonTappedEvent = (redirect: 'ios' | 'android' | 'web') => {
    logEvent(MetricsEvents.OnboardingDownloadScreenButtonTapped, { redirect });
  };

  const handleContinue = () => {
    dispatch(
      updateBot({
        avatar_generation: 'v2',
      }),
    );
    dispatch(replace(Routes.Chat));
    prepareMainRouteAfterOnboarding();

    setPasswordAlreadyAdded(false);
    logButtonTappedEvent('web');
  };

  const appLink = decodeURIComponent(
    params?.get('onelink') || 'https://replika.com/get',
  );

  return (
    <StyledAuthForm>
      <SignupChoosePlatformTitle>
        Download Replika app
      </SignupChoosePlatformTitle>
      <SignupChoosePlatformText>
        Click below to download Replika on your preferred platform or continue
        using the web version.
      </SignupChoosePlatformText>
      <SignupChoosePlatformButtons>
        <SolidButton
          as="a"
          href={appLink}
          target="_blank"
          onClick={() => logButtonTappedEvent('ios')}
        >
          Get iOS app
        </SolidButton>
        <SolidButton
          as="a"
          href={appLink}
          target="_blank"
          onClick={() => logButtonTappedEvent('android')}
        >
          Get Android app
        </SolidButton>
        <Desktop>
          <SolidButton onClick={handleContinue}>Stay on Web</SolidButton>
        </Desktop>
      </SignupChoosePlatformButtons>
    </StyledAuthForm>
  );
}

const StyledAuthForm = styled(AuthFormWithAvatar)`
  @media ${(p) => p.theme.breakpoints.mobile} {
    background: linear-gradient(
      180deg,
      rgb(92 116 204 / 0.9) 0%,
      rgb(33 52 122 / 0.9) 100%
    );
    position: absolute;
    inset: 0;
    justify-content: flex-end;
    padding: 30px 15px 15px;

    ${AuthFormInner} {
      flex: 0 0 auto;
    }
  }
`;

const SignupChoosePlatformTitle = styled.h2`
  color: ${(p) => p.theme.fgColor};
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  margin: 0;
`;

const SignupChoosePlatformText = styled.p`
  color: ${(p) => p.theme.fgColor};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
`;

const SignupChoosePlatformButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0 0;
  width: 100%;

  @media ${(p) => p.theme.breakpoints.tablet} {
    flex-direction: row;
    justify-content: center;
  }
`;
