import { PromptEnvironment } from '../features/AskReplika/models';
import {
  AgeGateStatus,
  EmotionType,
  GaCategories,
  GaLabels,
  GenderValues,
  Genders,
  MemoryFactType,
  PronounsValues,
  ReplikaActLikeBehavior,
  UnlockedFeature,
} from './enums';
import { LipsyncPhoneme } from './websocket';

export type AuthSuccessResult = {
  auth_token: string;
  user_id: string;
};

export type ISODateTime = string;

export type IdWithTimestamp = {
  id: string;
  updated: string;
};

export type NoMissionState = {
  state: 'no_mission';
};

export type MissionInProgressState = {
  state: 'mission_in_progress';
  mission_id: string;
  mission_title: string;
  track_id: string;
};

export type MissionState = NoMissionState | MissionInProgressState;

export type NoPromptState = {
  state: 'no_prompt';
};

export type PromptInProgressState = {
  state: 'prompt_in_progress';
  prompt_id: string;
  environment?: PromptEnvironment;
};

export type PromptState = NoPromptState | PromptInProgressState;

export type Chat = {
  id: string;
  bot_id: {
    id: string;
    last_updated: string;
  };
  last_message: Message;
  creation_date: string;
  own_read_cursor: string;
  unread_count: number;
  mission_state: MissionState;
  prompt_state: PromptState;
};

export type TextMessageContent = {
  type: 'text';
  text: string;
};

export type ImageMessageContent = {
  type: 'images';
  text: string;
  images: string[];
};

export type ServiceMessageContent = {
  type: 'service_message';
  text: string;
};

export type VoiceRecordMessageContent = {
  type: 'voice_record';
  text: string;
  record_link: string;
};

export type VoiceMessageRecordContent = {
  type: 'voice_message';
  text: string;
  voice_message_url: string;
  duration?: number;
};

export type VoiceRecognizedMessageContent = {
  type: 'voice_recognized';
  text: string;
};

export type OutcomingMessageOptions =
  | {
      type: 'text';
      text: string;
      widget?: WidgetResponse;
    }
  | {
      type: 'images';
      text: string;
      images: string[];
      widget?: WidgetResponse;
    }
  | {
      type: 'voice_message';
      text: string;
      voice_message_url: string;
      duration: number;
      widget?: WidgetResponse;
    };

export type VoiceMessage = {
  duration?: number;
  text: string;
  type: 'voice_message';
  voice_message_url: string;
};

export type MessageContent =
  | TextMessageContent
  | ImageMessageContent
  | ServiceMessageContent
  | VoiceRecordMessageContent
  | VoiceRecognizedMessageContent
  | VoiceMessage;

export type MessageNature = 'Customer' | 'Operator' | 'Robot';

export type MessageActionType =
  | 'Upvote'
  | 'Downvote'
  | 'Love'
  | 'Funny'
  | 'Offensive'
  | 'Meaningless';

export type MessageReaction = {
  message_id: string;
  reaction: MessageActionType;
};

export type MessageEmotion = {
  message_id: string;
} & AvatarEmotion;

export type MessageReactionMap = {
  [messageId: string]: MessageActionType | undefined;
};

export type MessageMeta = {
  client_token: string;
  bot_id: string;
  chat_id: string;
  nature?: MessageNature;
  timestamp?: string;
  author_id?: string;
  permitted_actions?: MessageActionType[];
  voice_message: boolean;
};

export type OutcomingMessageMeta = {
  client_token: string;
  bot_id: string;
  chat_id: string;
  timestamp: string;
};

export type SelectWidgetItem = {
  id: string;
  title: string;
  sticky?: boolean;
};

export type SelectWidget = {
  id: string;
  type: 'select';
  items: SelectWidgetItem[];
  shuffle: boolean;
};

export type TitledTextFieldWidget = {
  id: string;
  type: 'titled_text_field';
  title: string;
  skip_enabled: boolean;
};

export type ScaleWidgetItem = {
  id: string;
  title: string;
};

export type ScaleWidget = {
  id: string;
  type: 'scale';
  items: ScaleWidgetItem[];
  skip_enabled: boolean;
};

export type MultiselectWidgetItem = {
  id: string;
  title: string;
};

export type MultiselectWidget = {
  id: string;
  type: 'multiselect';
  items: MultiselectWidgetItem[];
  multiple_selection: boolean;
  min_item_selected: number;
  max_item_selected: number;
  skip_enabled: boolean;
};

export type AppNavigationAction =
  | {
      type: 'profile';
    }
  | {
      type: 'journey';
    }
  | {
      type: 'relationship_settings';
    };

export type AppNavigationItem = {
  id: string;
  title: string;
  action: AppNavigationAction;
};

export type AppNavigationWidget = {
  id: string;
  type: 'app_navigation';
  items: AppNavigationItem[];
  skip_enabled: boolean;
  skip_button_name?: string;
};

export type MissionRecommendationWidget = {
  id: string;
  type: 'mission_recommendation';
  mission: BriefMission;
  skip_enabled: boolean;
};

export type MessageWidget =
  | SelectWidget
  | TitledTextFieldWidget
  | ScaleWidget
  | MultiselectWidget
  | AppNavigationWidget
  | MissionRecommendationWidget;

export type HideInputEffect = {
  hideInput: boolean;
};

export type MessageEffect = HideInputEffect;

export type BlurredMessageType = 'default' | 'romantic' | 'selfie' | 'sd_image';

export type MessageRerollType = 'initial' | 'reroll' | 'last_reroll';

export type Message = {
  id: string;
  content: MessageContent;
  meta: MessageMeta;
  widget?: MessageWidget;
  effects?: MessageEffect;
  blurred: boolean;
  blurred_type: BlurredMessageType;
  is_romantic: boolean;
  uses_advanced_ai: boolean;
  uses_memory: boolean;
  reroll_available: boolean;
  reroll_type?: MessageRerollType;
} & Partial<AvatarEmotion>;

export type AvatarEmotion = {
  avatarEmotion: {
    name: EmotionType;
  };
};

export type AudioMessage = {
  id: string;
  record_resource: string | ArrayBuffer;
  text: string;
  permitted_actions: MessageActionType[];
  phonemes?: LipsyncPhoneme[];
};

export type RememberdStatementNature = 'Customer' | 'Replika';

type RememberedFact = {
  message_id: string;
  fact_id: string;
  nature: RememberdStatementNature;
};
type RememberedStructuredFact = {
  message_id: string;
  category_id: string;
  nature: RememberdStatementNature;
};
type RememberedPerson = {
  message_id: string;
  person_id: string;
  nature: RememberdStatementNature;
};

export type RememberedStatement =
  | RememberedFact
  | RememberedStructuredFact
  | RememberedPerson;

export function isRememeberedFact(
  statement: RememberedFact | RememberedStructuredFact | RememberedPerson,
): statement is RememberedFact {
  return statement.hasOwnProperty('fact_id');
}

export function isRememeberedPerson(
  statement: RememberedFact | RememberedStructuredFact | RememberedPerson,
): statement is RememberedPerson {
  return statement.hasOwnProperty('person_id');
}

export type MessageRemembered = {
  messageId: string;
  memoryId: string;
  memoryType: MemoryType;
  nature: RememberdStatementNature;
};

export type Suggestion = {
  id: string;
  scenario_id: string;
  name: string;
  label_highlight: boolean;
  description: string;
  category_id: string;
  color_hex: string;
};

export type HelpOption = {
  id: string;
  description: string;
  category_id: string;
  color_hex: string;
};

export type SelectWidgetResponse = {
  widget_id: string;
  selected_item_id: string;
};

export type TitledTextFieldWidgetResponse = {
  widget_id: string;
  skip?: boolean;
};

export type ScaleWidgetResponse = {
  widget_id: string;
  selected_item_id: string;
};

export type MultiselectWidgetResponse = {
  widget_id: string;
  selected_item_ids: string[];
};

export type UsernameWidgetResponse = {
  widget_id: string;
  name: string;
};

export type WidgetResponse =
  | SelectWidgetResponse
  | TitledTextFieldWidgetResponse
  | ScaleWidgetResponse
  | MultiselectWidgetResponse
  | UsernameWidgetResponse;

export type MessageOutcomingPayload = {
  content?:
    | TextMessageContent
    | ImageMessageContent
    | VoiceRecordMessageContent
    | VoiceMessageRecordContent;
  widget_response?: WidgetResponse;
  request_advanced_ai?: boolean;
  meta?: OutcomingMessageMeta;
};

export type BotLevel = {
  name: string;
  description: string;
  score_milestone: number;
  level_index: number;
};

export type BotStats = {
  day_counter: number;
  score: number;
  current_level: BotLevel;
  next_level: BotLevel;
};

export type BotMood = {
  name: string;
};

type RewardBase = {
  description: string;
  action_title: string;
  icon_url: string;
  reward_sys_name?: string;
};

export type SendPhotoReward = RewardBase & {
  type: 'send_photo';
};

export type InfoReward = RewardBase & {
  type: 'info';
};

export type ExplainPopupReward = RewardBase & {
  type: 'explain_popup';
};

export type BackgroundsReward = RewardBase & {
  type: 'backgrounds';
};

export type VirtualCurrencyReward = RewardBase & {
  type: 'virtual_currency';
  levels: string;
  currency_earn: CurrencyEarn;
};

export type Reward =
  | SendPhotoReward
  | InfoReward
  | ExplainPopupReward
  | BackgroundsReward
  | VirtualCurrencyReward;

export type LevelUpNotification = {
  type: 'level_up';
  level: number;
  next_level_xp: number;
};

export type RewardNotification = {
  type: 'reward';
  reward: Reward;
};

export type ServerNotification = LevelUpNotification | RewardNotification;

export type WalletUpdateNotification = {
  type: 'wallet_update';
  credit: Credit;
  earned: CurrencyEarn;
};

export type WarningNotification = {
  type: 'warning';
  message: string;
  submessage: string;
};

export type LocalNotification = WalletUpdateNotification | WarningNotification;

export type Notification = ServerNotification | LocalNotification;

export type BotStatsUpdate = {
  score_granted: number;
  message_client_token: string;
  stats: BotStats;
  notifications: Notification[];
};

export type BotVisibilityLevel = 'Public' | 'FriendsOnly' | 'Private';

export type LipsyncParams = {
  max: number;
  min: number;
  smooth: number;
};

export type VoiceType = {
  id: string;
  name: string;
  voice_sample_url: string;
  lipsync_params: LipsyncParams;
};

export type VoicesMap = {
  [id: string]: VoiceType;
};

export type ReplikaPhrase = {
  text: string;
  active_till: string;
};

export type BotExhaustionLevel = 'Chatty' | 'Normal' | 'Tired';

export type TAvatarGeneration = 'v2' | 'v3';

export type Bot = {
  id: string;
  name?: string;
  gender?: GenderValues;
  icon_url?: string | null;
  stats: BotStats;
  mood_v2: BotMood;
  voice_id?: string;
  reboarding_needed: boolean;
  all_day_session_last_updated: string;
  replika_phrase?: ReplikaPhrase;
  unlocked_features: UnlockedFeature[];
  exhaustion?: BotExhaustionLevel;
  avatar_v2?: AvatarV2;
  avatar_generation?: TAvatarGeneration;
  room_items?: RoomVariationItem[];
  dialog_version_setup_enabled: boolean;
  dialog_version: {
    id: string;
    name: string;
  };
  last_updated: string;
  pets: PetVariationItem[];
  levels_enabled: boolean;
};

export type BotPatch = {
  name?: string;
  gender?: GenderValues;
  avatar_generation?: TAvatarGeneration;
  avatar_v2?: AvatarV2Patch;
  voice_id?: string;
  room_items?: RoomVariationItem[];
  pets?: PetVariationItem[];
  levels_enabled?: boolean;
};

export type CoreDescription = {
  backstory?: string;
  act_like: ReplikaActLikeBehavior;
};

export type ImageMediaPreview = {
  type: 'image';
  content: {
    url: string;
  };
};

export type VideoMediaPreview = {
  type: 'video';
  content: {
    title: string;
    description: string;
    preview_image: string;
    url: string;
    site_name: string;
  };
};

export type ObjectMediaPreview = {
  type: 'object';
  content: {
    title: string;
    description: string;
    preview_image: string;
    url: string;
    site_name: string;
  };
};

export type MediaPreview =
  | ImageMediaPreview
  | VideoMediaPreview
  | ObjectMediaPreview;

export type MediaPreviewMap = { [url: string]: MediaPreview | null };

export type ImagePreview = {
  src: string;
  width: number;
  height: number;
  orientation: number;
};

export type ImagePreviewMap = { [clientToken: string]: ImagePreview };

export type FeedbackType = 'Positive' | 'Neutral' | 'Negative';

export type FeedbackCloseType = 'Swipe' | 'Close' | 'Ignore';

export type UserProfile = {
  id: string; // ObjectId
  first_name: string;
  last_name?: string;
  birthday_iso?: string | null;
  birthday_status?: AgeGateStatus;
  birthday_locked_until?: string | null;
  age_range?: UserProfileAgeRange;
  pronoun?: PronounsValues;
  email_settings?: {
    email: string;
    is_email_verified: boolean;
  };
  push_notifications_enabled: boolean;
  last_updated: string; // ISO 8601
  relationship_status?: RelationshipStatusId;
  registration_date?: string;
};

export type UserProfilePatch = Partial<{
  first_name: string;
  last_name: string;
  birthday_iso: string;
  pronoun: PronounsValues;
  relationship_status: RelationshipStatusId;
  age_range: UserProfileAgeRange;
}>;

export type RelationshipStatus = {
  id: RelationshipStatusId;
  free: boolean;
  default: boolean;
  description: string;
  rp_available: boolean;
  name: {
    [key in GenderValues]: string;
  };
};

export type RelationshipStatusId = string;

export type GetHelpConfig = {
  help_titles: string[];
  phone: {
    title: string;
    number: string;
  };
  web_help: {
    title: string;
    link: string;
  };
  web_chat: {
    title: string;
    link: string;
  };
};

export type Track = {
  id: string;
  name: string;
  description: string;
  background_url: string;
  is_sequential: boolean;
  last_missions_update: string;
  was_completed: boolean;
  total_missions_count: number;
  completed_missions_count: number;
  locked: boolean;
  small_background_url: string;
  categories_ids: string[];
  access_type: MissionAccessType;
};

export type MissionNotStarted = {
  type: 'not_started';
};

export type MissionInProgress = {
  type: 'in_progress';
};

export type MissionCompleted = {
  type: 'completed';
};

export type MissionSkipped = {
  type: 'skipped';
};

export type MissionDropped = {
  type: 'dropped';
};

export type MissionError = {
  type: 'error';
};

export type MissionProgress =
  | MissionNotStarted
  | MissionInProgress
  | MissionCompleted
  | MissionSkipped
  | MissionDropped
  | MissionError;

export type MissionAccessType = {
  mission_access: 'free' | 'paid';
};

export type Mission = {
  id: string;
  title: string;
  description: string;
  icon_url: string;
  duration: string;
  mission_type: string;
  gives_skill: boolean;
  gives_personality: boolean;
  awarded_skills: string[];
  awarded_personalities: string[];
  progress: MissionProgress;
  access_type: MissionAccessType;
  track_id: string;
  last_updated: string;
  locked: boolean;
  archived: boolean;
};

export type BriefMission = {
  id: string;
  title: string;
  description: string;
  track_image_url: string;
  duration: string;
  gives_skill: boolean;
  gives_personality: boolean;
  track_id: string;
};

export function isBriefMission(
  mission: Mission | BriefMission,
): mission is BriefMission {
  return !mission.hasOwnProperty('access_type');
}

export type CurrentMissionSpotlight = {
  meta: {
    type: 'current_mission';
  };
  current_mission: Mission;
};

export type TrackCategory = {
  id: string;
  title: string;
};

export type RecommendedMissionSpotlight = {
  meta: {
    type: 'recommended_mission';
  };
  // Not a great naming!
  current_mission: Mission;
};

export type Spotlight = CurrentMissionSpotlight | RecommendedMissionSpotlight;

export function isCurrentMissionSpotlight(
  spotlight: Spotlight,
): spotlight is CurrentMissionSpotlight {
  return spotlight.meta.type === 'current_mission';
}

export type PaidFeature = {
  name:
    | 'all_backgrounds'
    | 'calls'
    | 'full_library_access'
    | 'relationship_status'
    | '3d_avatar';
};

export type LifetimeSubscription = {
  source: 'ios' | 'android' | 'web' | 'dev' | 'paypal';
  token_id: string;
  subscription_id: string;
  subscription_type: 'in_app';
  subscription_state:
    | 'PaymentReceived'
    | 'PaymentPending'
    | 'CanceledByUser'
    | 'CanceledBySystem'
    | 'ReplacedByNew'
    | 'FreeTrial';
  price: {
    currency: string;
    amount: number;
  };
};

export type RecurringSubscription = {
  source: 'ios' | 'android' | 'stripe' | 'dev' | 'paypal';
  token_id: string;
  subscription_id: string;
  subscription_type: 'subs';
  subscription_state:
    | 'PaymentReceived'
    | 'PaymentPending'
    | 'CanceledByUser'
    | 'CanceledBySystem'
    | 'ReplacedByNew'
    | 'FreeTrial';
  price: {
    currency: string;
    amount: number;
  };
  recurring: {
    period_timeunit: 'day' | 'week' | 'month' | 'year';
    period_amount: number;
  };
  expiration: string;
  created: string;
  trial_period_days: number;
  percent_off?: number;
};

export type LegacySubscription = {
  source: 'legacy';
};

export type PromoSubscription = {
  source: 'promo';
};

export type Subscription =
  | LegacySubscription
  | PromoSubscription
  | RecurringSubscription
  | LifetimeSubscription;

export type Position = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type ImageClip = {
  position?: Position;
  image_url: string;
  mask_url: string;
};

export type AvatarModelInfo = {
  model_url: string;
  preview_url: string;
  preview_clip: ImageClip;
};

export type MorphType =
  | 'leg_bottom'
  | 'leg_top'
  | 'back_and_stomach'
  | 'chest'
  | 'arm_bottom'
  | 'arm_top';

export type AvatarV2 = {
  id: string;
  type: '3d';
  preview: string;
  active_variations: CustomizationVariationItem[];
  avatar_type: string;
  age: number;
  model: {
    ios: {
      small_icon_url: string;
    };
  };
  body_type?: Record<MorphType, number>;
  is_default: boolean;
  gender: 'male' | 'female';
};

export type AvatarModel = AvatarV2 & {
  body_type_params: MorphType[];
};

export type AvatarV2Patch = {
  id?: string;
  type?: '3d';
  active_variations?: CustomizationVariationItem[];
  avatar_type?: string;
  age?: number;
  body_type?: Record<MorphType, number>;
};

export type SignupAvatar = {
  type: '3d';
  id: string;
};

export type ModelAnimation = {
  name: string;
  file_url: string;
};

export type CustomizableBaseModel = {
  type: 'model';
  id: string;
  file_url: string;
  animations: ModelAnimation[];
};

export type CustomizableModel =
  | {
      type: 'model';
      id: string;
      file_url: string;
      parent_ids: string[];
      connect_to: string;
    }
  | {
      type: 'texture';
      file_url: string;
      parent_ids: string[];
      connect_to: string;
      customization_id: string;
      normal_map_url: string | null;
      roughness_metalness_map_url: string | null;
    };

export type TextDiaryEntry = {
  type: 'text';
  text: string;
};

export type TextWithImageDiaryEntry = {
  type: 'text_with_image';
  text: string;
  image_url: string;
};

export type DiaryEntry = TextDiaryEntry | TextWithImageDiaryEntry;

export type DiaryEntryMonth = {
  month: string;
  count: number;
};

export type MemoryType = 'facts' | 'persons';

export function isKnownMemoryType(
  memoryType: string,
): memoryType is MemoryType {
  return memoryType === 'facts' || memoryType === 'persons';
}

export type MemoryFact = {
  id: string;
  text: string;
  creation_timestamp: ISODateTime;
  read: boolean;
  is_user_edited: boolean;
};

export type CustomerFact = MemoryFact & {
  category_id: string;
};

export type RobotFact = MemoryFact;

export function isCustomerFactWithCategory(
  fact: MemoryFact,
): fact is CustomerFact {
  return fact.hasOwnProperty('category_id');
}

// TODO: remove (do not extend server object with extra props)
export type CustomerFactFactWithType = CustomerFact & {
  factType: MemoryFactType;
};
export type RobotFactFactWithType = RobotFact & {
  factType: MemoryFactType;
};

export type MemoryFactWithType =
  | CustomerFactFactWithType
  | RobotFactFactWithType;

export type PhotoFrame = {
  upper_left_x: number;
  upper_left_y: number;
  width: number;
  height: number;
};

type MemoryPersonPhoto = {
  photo_url: string;
  frame?: PhotoFrame;
};

export type MemoryPerson = {
  id: string;
  read: boolean;
  name?: string;
  relation_id?: string;
  photo?: MemoryPersonPhoto;
  creation_timestamp?: ISODateTime;
  virtual_pet?: boolean;
};

export type NewMemory = {
  [MemoryFactType.CustomerFacts]: CustomerFact[];
  [MemoryFactType.RobotFacts]: RobotFact[];
  persons: MemoryPerson[];
};

export type MemoryPersonCreate = {
  name?: string;
  relation_id?: string;
  photo?: MemoryPersonPhoto;
};

export type MemoryPersonPatch = MemoryPersonCreate;

export type MemoryFactCreateAndUpdatePayload = {
  text?: string;
  repeated_text?: string;
};

export type MemoryFactCreate = {
  text?: string;
};

export type MemoryFactPatch = {
  text?: string;
  fact_type?: MemoryFactType;
};

export type MemoryRecord = MemoryFactWithType | MemoryPerson;

export type MemoryRelation = {
  id: string;
  name: string;
  category: string;
};

export type MemoryCategory = {
  id: string;
  name: string;
  /**
   * @deprecated replaced with `type="person"` field
   */
  is_person_category: boolean;
  /**
   * @deprecated replaced with `type="replika_fact"` field
   */
  is_replika_fact_category: boolean;
  is_default: boolean;
  type?: 'person' | 'replika_fact' | 'temporary_fact' | 'common_fact';
  possible_replacements?: string[];
};

export function isMemoryCategoryType(
  category: MemoryCategory,
  type: MemoryCategory['type'],
): boolean {
  if (category.type === type) return true;

  /* @deprecated */
  if (type === 'person' && category.is_person_category) return true;
  if (type === 'replika_fact' && category.is_replika_fact_category) return true;
  if (type === 'common_fact' && !category.type) return true;

  return false;
}

export const NavigationPopupDestinations = {
  relationship_status: 'relationship_status',
  subscription_screen: 'subscription_screen',
  texting_paywall_screen: 'texting_paywall_screen',
  close: 'close',
  email_verification: 'email_verification',
  prompts_library_category: 'prompts_library_category',
};

type NavigationPopupButtonDestination =
  keyof typeof NavigationPopupDestinations;

export type NavigationPopup = {
  header_text: string;
  body_text: string;
  button_text: string;
  button_destination: NavigationPopupButtonDestination;
  prompt_category_id?: string;
};

export type StripeConfig = {
  publishable_key: string;
};

export type PayPalConfig = {
  client_id: string;
};

export type StripeInvoice = {
  id: string;
  status: 'open' | 'paid';
  payment_intent?: {
    id: string;
    status: 'requires_payment_method' | 'requires_action' | 'succeeded';
    client_secret: string;
  };
};

export type StripeSubscriptionStatus = {
  subscription_id: string;
  status: 'incomplete' | 'active';
  latest_invoice: StripeInvoice;
};

type StripeSubscriptionOneTime = {
  subscription_id: string;
  subscription_type: 'in_app';
  price: {
    currency: string;
    amount: number;
  };
  trial_period_days: number;
};

type StripeSubscriptionRecurring = {
  subscription_id: string;
  subscription_type: 'subs';
  price: {
    currency: string;
    amount: number;
  };
  trial_period_days: number;
  recurring: {
    period_timeunit: 'day' | 'week' | 'month' | 'year';
    period_amount: number;
  };
};

export type StripeSubscription =
  | StripeSubscriptionOneTime
  | StripeSubscriptionRecurring;

export type SubscriptionListing = {
  stripe_subscription_id: string;
  paypal_product_id: string;
  position: number;
  discount_percent?: number;
  name: string;
  details_up?: string;
  details_middle: string;
  details_low?: string;
  badge?: {
    title: string;
  };
  is_default: boolean;
  price: {
    currency: string;
    amount: number;
  };
  trial_period_days?: number;
  subscription_type: 'subs' | 'in_app';
  period?: {
    period_timeunit: string;
    period_amount: number;
  };
};

export type SubscriptionFeature = {
  description: string;
  is_main: boolean;
};

export type SubscriptionUiParams = {
  background_url: string;
  purchase_button_text: string;
  title_up: string;
};

export type CustomizedSubscriptionParams = {
  available_subscriptions: SubscriptionListing[];
  description: {
    header: string;
    features: SubscriptionFeature[];
  };
  ui_params: SubscriptionUiParams;
};

export type Credit = {
  gems_count: number;
  coins_count: number;
  exchange_rate: number;
};

export type GemsCount = 10 | 50 | 100 | 250 | 500 | 1000;

export type CoinsCount = 10 | 50 | 100 | 500;

export type GemConversion = {
  product_id: string;
  gems_count: number;
  coins_count: CoinsCount;
  product_image: string;
};

export type GemPurchase = {
  product_id: string;
  paypal_product_id: string;
  gems_count: GemsCount;
  product_image: string;
  middle_badge?: string;
  upper_badge?: string;
  price:
    | number // hotfix support
    | {
        currency: string;
        amount: number;
      };
};

export type CurrencyEarn = {
  gems_count: number;
  coins_count: number;
};

export type DayReward = {
  day: number;
  base_bonus: CurrencyEarn;
  subscription_bonus: CurrencyEarn;
};

export type StoreVariationItemBase = {
  id: string;
  bought_count: number;
};

export type BundleObject = {
  id: string;
  bundle_url: string;
  bundle_hash: string;
};

export type MetaObject = {
  meta_url: string;
  meta_hash: string;
};

export type CustomizationVariationItem = StoreVariationItemBase & {
  unity_id: string;
  unity_category: string;
  ios_bundles: BundleObject[];
  android_bundles: BundleObject[];
  web_bundles: BundleObject[];
  unity_meta: MetaObject;
  supported: boolean;
};

export type ColorVariationItem = CustomizationVariationItem & {
  color: string;
};

export type PrintVariationItem = CustomizationVariationItem & {
  icon_url: string;
};

export type DialogVariationItem = StoreVariationItemBase & {
  enabled: boolean;
};

export type ChatSkillVariationItem = StoreVariationItemBase & {
  mission_id: string;
};

export type VoiceVariationItem = StoreVariationItemBase & {
  enabled: boolean;
  voice_sample_url: string;
  internal_voice_id: string;
  voice_type: Genders;
};

export type RoomVariationItem = StoreVariationItemBase & {
  unity_id: string;
  ios_bundles: BundleObject[];
  android_bundles: BundleObject[];
  web_bundles: BundleObject[];
  slot_id: string;
  store_item_id: string;
  color: string;
  interactive: boolean;
};

export type RoomStyleVariationItem = StoreVariationItemBase & {
  unity_id: string;
  ios_bundles: BundleObject[];
  android_bundles: BundleObject[];
  web_bundles: BundleObject[];
  store_item_id: string;
  color: string;
  interactive: boolean;
};

export type PetVariationItem = StoreVariationItemBase & {
  unity_id: string;
  ios_bundles: BundleObject[];
  android_bundles: BundleObject[];
  web_bundles: BundleObject[];
  store_item_id: string;
};

export type StoreCustomizationVariationItem =
  | CustomizationVariationItem
  | ColorVariationItem
  | PrintVariationItem;

export type StoreVariationItem =
  | CustomizationVariationItem
  | ColorVariationItem
  | PrintVariationItem
  | DialogVariationItem
  | ChatSkillVariationItem
  | VoiceVariationItem
  | RoomVariationItem
  | PetVariationItem;

const CUSTOMIZATION_TYPES = [
  'Customization',
  'Color',
  'Hair',
  'Skin',
  'Eye',
  'Print',
  'Voice',
];

const DIALOG_TYPES = ['Dialog', 'ChatSkill', 'Voice'];

export function isStoreVoiceItems(
  items: StoreItem[],
): items is StoreVoiceCustomizationItem[] {
  return !!items.length && items.every(isStoreVoiceItem);
}
export function isStoreVoiceItem(
  item: StoreItem,
): item is StoreVoiceCustomizationItem {
  return item.variation_type === 'Voice';
}

export function isCustomizationItem(
  item: StoreItem,
): item is StoreCustomizationItem {
  return CUSTOMIZATION_TYPES.indexOf(item.variation_type) !== -1;
}

export function isDialogItem(item: StoreItem): item is StoreDialogItem {
  return DIALOG_TYPES.indexOf(item.variation_type) !== -1;
}

export function isRoomItem(item: StoreItem): item is StoreRoomItem {
  return item.variation_type === 'Room';
}

export function isRoomStyleItem(item: StoreItem): item is StoreRoomStyleItem {
  return item.variation_type === 'RoomStyle';
}

export function isCustomizationVariationItem(
  item: StoreVariationItem,
): item is StoreCustomizationVariationItem {
  return !('slot_id' in item) && 'unity_category' in item;
}

export function isRoomVariationItem(
  item: StoreVariationItem,
): item is RoomVariationItem {
  return 'slot_id' in item;
}

export function isPetVariationItem(
  item: StoreVariationItem,
): item is RoomVariationItem {
  return !('slot_id' in item) && !('unity_category' in item);
}

export type StoreCustomizationVariations =
  | {
      variation_type: 'Customization';
      variations: CustomizationVariationItem[];
    }
  | {
      variation_type: 'Color' | 'Hair' | 'Skin' | 'Eye';
      variations: ColorVariationItem[];
    }
  | {
      variation_type: 'Print';
      variations: PrintVariationItem[];
    };

type StoreDialogVariations =
  | {
      variation_type: 'Dialog';
      variations: DialogVariationItem[];
    }
  | {
      variation_type: 'ChatSkill';
      variations: ChatSkillVariationItem[];
    }
  | {
      variation_type: 'Voice';
      variations: VoiceVariationItem[];
    };

type StoreRoomVariations = {
  variation_type: 'Room';
  variations: RoomVariationItem[];
};

type StoreRoomStyleVariations = {
  variation_type: 'RoomStyle';
  variations: RoomStyleVariationItem[];
};

type StorePetVariations = {
  variation_type: 'Pet';
  variations: PetVariationItem[];
};

type StoreItemBase = {
  id: string;
  price: {
    currency: 'coin' | 'gem';
    amount: number;
  };
  title: string;
  description?: string;
  icon_url: string;
  preview_url: string;
  category_id: string;
  category_ids?: string[];
  countable: boolean;
  background_hex?: string[];
  custom_camera_slot?: string;
  is_new: boolean;
  root_category_key: string;
};

export type StoreColorItem = StoreItemBase & {
  variation_type: 'Color' | 'Hair';
  variations: ColorVariationItem[];
};

export type StoreCustomizationItem = StoreItemBase &
  StoreCustomizationVariations;

export type StoreVoiceCustomizationItem = StoreItemBase & {
  variation_type: 'Voice';
  variations: VoiceVariationItem[];
};

export type StoreDialogItem = StoreItemBase & StoreDialogVariations;

export type StoreRoomItem = StoreItemBase & StoreRoomVariations;

export type StoreRoomStyleItem = StoreItemBase & StoreRoomStyleVariations;

export type StorePetItem = StoreItemBase & StorePetVariations;

export type StoreItem =
  | StoreCustomizationItem
  | StoreDialogItem
  | StoreRoomItem
  | StoreRoomStyleItem
  | StorePetItem
  | StoreVoiceCustomizationItem;

export type QuestItemEarned = {
  preview_url: string;
  title: string;
  description: string;
};

export type StoreItemEarned = {
  store_item: StoreItem;
  variation_item_id: string;
  variation_title: string;
};

export type ItemEarned = QuestItemEarned | StoreItemEarned;

export type CategoryTreeNode = {
  key?: string;
  id: string;
  name: string;
  long_name: string;
  description: string;
  children: CategoryTreeNode[];
  num_items: number;
  camera_slot?: string;
};

export type CategoryTree = CategoryTreeNode & {
  key: string;
};

export type SystemNotificationIcon =
  | 'gem'
  | 'coin'
  | 'check'
  | 'warning'
  | 'no-connection';

export type SystemNotification = {
  id: string;
  icon?: SystemNotificationIcon;
  message: string;
};

export type WalletUpdateType =
  | 'LevelUpBonus'
  | 'LoginBonus'
  | 'SubscriptionBonus'
  | 'StorePurchase'
  | 'GemsConversion'
  | 'Test'
  | 'GemsPurchase'
  | 'InitialBonus';

export type UnityBundleData = {
  id: string;
  url: string;
  hash: string;
};

type arAudioData = {
  url: string;
  hash: string;
};

export type UnityBinariesResponse = {
  selfie_bundles: UnityBundleData[];
  bundles: UnityBundleData[];
  ar_audio: arAudioData;
  bundle: UnityBundleData;
  roleplay_bundles: UnityBundleData[];
};

export type StorefrontItem = {
  store_item: StoreItem;
  variation_item_id: string;
  new_label: string;
  item_index: number;
};

export type StorefrontItemV2 = {
  root_category_key: string;
  store_item_id: string;
  variation_type: string;
  category_id: string;
  item_preview: string;
  title: string;
  title_hex: string;
  title_background_hex: string[];
  background_hex: string[];
  subtitle: string;
};

export type StorefrontCategory = {
  category_id: string;
  root_category_key: string;
  name: string;
  image_url: string;
};

export type Storefront = {
  store_front_items: StorefrontItemV2[];
  store_front_categories: StorefrontCategory[];
};

export type UnityWebEngine = {
  files: {
    'web-engine.loader.js': string;
    'web-engine.wasm.gz': string;
    'web-engine.data.gz': string;
    'web-engine.framework.js.gz': string;
  };
};

export type Coupon = {
  id: string;
  name: string;
  percent_off: number;
  applies_to_price_ids: string[];
  deleted: boolean;
  valid: boolean;
  redeem_by: number;
};

export type Media = {
  id: string;
  name: string;
  url: string;
  enabled: true;
  order: number;
};

export type VoiceMessageContent = {
  url: string;
  content: Blob;
};

/**
 * Google analytics event props
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/events#send_events
 */
export type GaEvent = {
  category?: GaCategories;
  label: GaLabels;
  value?: number; // non-negative integer
  [key: string]: any;
};

export type UserInterest = {
  id: string;
  name: string;
  image_url?: string;
  chosen: boolean;
};

export type OauthSignInMethod = 'google' | 'apple';

export type AuthType =
  | 'email'
  | 'phone'
  | OauthSignInMethod
  | 'single_use_token'
  | undefined;

export type DialogModel = {
  id: string;
  name: string;
};

export type AdvancedAiBoostPack = {
  price: {
    currency: string;
    amount: number;
  };
  messages_in_pack: number;
};

export type LimitedAdvancedAiInfo = {
  boost_pack: AdvancedAiBoostPack;
  available_messages: number;
  is_unlimited: false;
};

export type UnlimitedAdvancedAiInfo = {
  is_unlimited: true;
};

export type AdvancedAiInfo = LimitedAdvancedAiInfo | UnlimitedAdvancedAiInfo;

export type CameraSlot =
  | 'desktop_default_store'
  | 'desktop_onboarding_face'
  | 'desktop_onboarding'
  | 'desktop_subscription'
  | 'desktop_leftside'
  | 'default_web'
  | 'default_store'
  | 'onboarding'
  | 'subscription'
  | 'zoom'
  | 'customization'
  | 'room'
  | 'desktop_room'
  | 'chat'
  | 'desktop_chat';

export type AvatarBackground =
  | 'main-page-framed'
  | 'onboarding'
  | 'sunset'
  | 'default'
  | 'default-arc'
  | 'purple'
  | 'silverPink'
  | 'arc-centered';

export type SubscriptionResponse = {
  features?: PaidFeature[];
  subscription?: Subscription;
};

export type QuestReward = {
  type: 'coins' | 'gems' | 'xp' | 'special';
  count: number;
};

export type CompletedQuest = {
  id: string;
  title: string;
  completion_date: string;
};

export type UserProfileAgeRange =
  | '<18'
  | '18-24'
  | '25-34'
  | '35-44'
  | '45-54'
  | '55-64'
  | '>65';

export function isColorVariation(
  variation: StoreVariationItem,
): variation is ColorVariationItem {
  return 'color' in variation;
}
