import { push } from 'connected-react-router';
import { format } from 'date-fns';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import CircleSpinner from '../../components/CircleSpinner';
import NoWrap from '../../components/NoWrap';
import LinkButton from '../../components/legacy/LinkButton';
import { Routes } from '../../types/enums';
import { Subscription } from '../../types/models';
import { isEnvironment } from '../../utils/environment';
import { dialogMobileMedia } from '../../utils/mobileMedia';
import {
  isLifetimeSubscription,
  isRecurringSubscription,
  isSubscriptionCancelled,
  isSubscriptionPending,
} from '../../utils/subscription';
import { TERMS_SUBS_REPLIKA_URL } from '../../utils/uri';
import useBotName from '../../utils/useBotName';
import StripeBadge from '../StripeBadge';
import { UpperBadge, UpperBadgeText } from '../UpperBadge';
import outlineCss from '../outlineCss';
import { useSubscriptionToken } from './hooks';

type Props = {
  className?: string;
  subscription: Subscription;
  header?: React.ReactNode;
  onCancel?: () => void;
};

const CANCELED_TITLE = 'Your subscription is cancelled';
export const PENDING_TITLE = 'Your payment is in progress';

const INSTANT_TRIAL_CANCEL_FAREWELL_DESCRIPTION =
  'Your subscription is canceled. Thank you for giving Pro a chance, and we hope to have you re-join in the future!';
const DEFAULT_FAREWELL_DESCRIPTION = (
  <>
    Thanks for giving it a try! <br />
    You can access Pro features until the end of your billing cycle.
  </>
);
export const PENDING_DESCRIPTION =
  'Please hold tight while your payment is being processed. Information will be updated as soon as it is ready.';

function getTexts(subscription: Subscription, botName: string) {
  switch (subscription.source) {
    case 'ios':
      return {
        title: 'You are paying through Apple App Store',
        description:
          'To change what you pay update your subscription settings of your App Store account.',
      };

    case 'android':
      return {
        title: 'You are paying through Google Play',
        description:
          'To change what you pay update your subscription settings of your Google Play account.',
      };

    case 'legacy':
    case 'stripe':
    case 'promo':
    case 'paypal':
      return {
        title: (
          <>
            Welcome to <NoWrap>Replika Pro</NoWrap>
          </>
        ),
        description: (
          <>
            You now have access to all features and conversations in the app.
            Enjoy spending time with <NoWrap>{botName}</NoWrap>!
          </>
        ),
      };

    default:
      return { title: '', description: '' };
  }
}

function SubscriptionCard({ subscription }: { subscription: Subscription }) {
  let period: string | undefined;
  let description: React.ReactNode | undefined;
  let priceAmount: number;

  const isTrial =
    isRecurringSubscription(subscription) &&
    subscription.subscription_state === 'FreeTrial' &&
    !!subscription.trial_period_days;

  const isCancelled =
    isRecurringSubscription(subscription) &&
    isSubscriptionCancelled(subscription);

  if (isRecurringSubscription(subscription)) {
    const { period_amount: periodAmount, period_timeunit: timeunit } =
      subscription.recurring;
    if (periodAmount > 1) {
      period = `${periodAmount}-${timeunit}`;
    } else {
      period = timeunit === 'day' ? 'daily' : `${timeunit}ly`;
    }

    priceAmount = subscription.price?.amount;

    let nextPayment = (
      <Desc>
        Next payment {format(new Date(subscription.expiration), 'PP')}
      </Desc>
    );

    if (subscription && priceAmount && subscription.percent_off && !isTrial) {
      priceAmount = (1 - subscription.percent_off / 100) * priceAmount;
      const nextPrice = (
        <small>
          (for {subscription.price.amount}{' '}
          <InlineCurrency>{subscription.price.currency}</InlineCurrency>)
        </small>
      );
      nextPayment = (
        <Desc>
          Next payment {format(new Date(subscription.expiration), 'PP')} <br />
          {nextPrice}
        </Desc>
      );
    }

    description = (
      <>
        <Desc>Created {format(new Date(subscription.created), 'PP')}</Desc>
        {isCancelled ? (
          <ErrorDesc>
            Expires {format(new Date(subscription.expiration), 'PP')}
          </ErrorDesc>
        ) : (
          nextPayment
        )}
      </>
    );
  } else if (isLifetimeSubscription(subscription)) {
    period = 'lifetime';
    description = (
      <LifetimeDesc>
        You have an unlimited subscription, no further payments required.
      </LifetimeDesc>
    );
  }

  return (
    <SubscriptionCardRoot $isTrial={isTrial}>
      {isTrial && isRecurringSubscription(subscription) && (
        <UpperBadge>
          <UpperBadgeText>{`${subscription.trial_period_days}-day trial`}</UpperBadgeText>
        </UpperBadge>
      )}
      {isRecurringSubscription(subscription) &&
      (subscription.source === 'stripe' || subscription.source === 'paypal') ? (
        <SubscriptionInfo $isTrial={isTrial}>
          <SubscriptionPrice>
            {subscription.price.amount.toFixed(2)}{' '}
            <Currency>{subscription.price.currency}</Currency>
          </SubscriptionPrice>
          <SubscriptionPeriod>
            billed {period}
            {isCancelled && <CancelledTitle>Cancelled</CancelledTitle>}
          </SubscriptionPeriod>
        </SubscriptionInfo>
      ) : null}

      <SubscriptionDescription>{description}</SubscriptionDescription>
    </SubscriptionCardRoot>
  );
}

const CancelledTitle = styled.div`
  text-transform: none;
  color: ${(p) => p.theme.errorLightFgColor};
`;

const SubscriptionCardRoot = styled.div<{ $isTrial: boolean }>`
  margin: 30px 0 10px;
  position: relative;
  border: 2px solid #fff;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    rgba(0, 0, 0, 0.08);
  color: ${(p) => p.theme.fgColor};
  font-size: 24px;
  min-width: 240px;
  padding: 15px 30px;
  height: ${(p) => (p.$isTrial ? '155px' : '130px')};
  border-radius: 25px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${dialogMobileMedia`
    max-width: 100%;
  `}
`;

const SubscriptionInfo = styled.div<{ $isTrial?: boolean }>`
  margin-top: ${(p) => (p.$isTrial ? '20px' : '5px')};
  margin-bottom: 20px;
`;

const SubscriptionPrice = styled(NoWrap)`
  color: ${(p) => p.theme.fgColor};
  font-size: 20px;
  line-height: 22px;
  font-family: ${(p) => p.theme.fonts.display};
`;

const SubscriptionPeriod = styled.div`
  color: ${(p) => p.theme.dimmedFgColor};
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
`;

const SubscriptionDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Desc = styled.p`
  margin: 0;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 18px;
  color: ${(p) => p.theme.fgColor};
`;

const ErrorDesc = styled(Desc)`
  color: ${(p) => p.theme.errorLightFgColor};
`;

const LifetimeDesc = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  color: ${(p) => p.theme.fgColor};
  max-width: 180px;
`;

const Currency = styled.span`
  color: ${(p) => p.theme.fgColor};
  font-size: 14px;
  line-height: 18px;
  font-family: ${(p) => p.theme.fonts.display};
`;

const InlineCurrency = styled.span`
  font-family: ${(p) => p.theme.fonts.display};
`;

function ActiveSubscription({
  className,
  subscription,
  header,
  onCancel,
}: Props) {
  const dispatch = useDispatch();
  const { data, isLoading: isWebUrlLoading } = useSubscriptionToken();
  const botName = useBotName();
  const texts = getTexts(subscription, botName);
  const hasInstantTrialCancel = useSelector((state) =>
    state.ws.capabilities.includes('stripe_instant_trial_cancel'),
  );

  const isCancelled =
    isRecurringSubscription(subscription) &&
    isSubscriptionCancelled(subscription);

  const isPending = isSubscriptionPending(subscription);

  const title = isCancelled
    ? CANCELED_TITLE
    : isPending
      ? PENDING_TITLE
      : texts.title;
  const farewellDescription = hasInstantTrialCancel
    ? INSTANT_TRIAL_CANCEL_FAREWELL_DESCRIPTION
    : DEFAULT_FAREWELL_DESCRIPTION;

  const description = isCancelled
    ? farewellDescription
    : isPending
      ? PENDING_DESCRIPTION
      : texts.description;

  const cancellable =
    (subscription.source === 'stripe' || subscription.source === 'paypal') &&
    isRecurringSubscription(subscription) &&
    !isSubscriptionCancelled(subscription);
  const cancellableOnDev =
    !cancellable &&
    !isCancelled &&
    subscription.source === 'stripe' &&
    !isEnvironment('production');

  const showSubscriptionCard =
    (isRecurringSubscription(subscription) &&
      subscription.recurring.period_amount > 0) ||
    isLifetimeSubscription(subscription);

  const handleCancel = () => {
    if (data) {
      dispatch(push(Routes.CancelFlow + data.search));
    } else {
      dispatch(push(Routes.SubscriptionCancel));
      onCancel?.();
    }
  };

  return (
    <ActiveSubscriptionRoot className={className}>
      <Title>{title}</Title>
      {header}
      <Description>{description}</Description>
      {showSubscriptionCard ? (
        <SubscriptionCard subscription={subscription} />
      ) : (
        <SubscriptionCardPlaceholder />
      )}
      <Actions>
        {cancellable && (
          <StyledLinkButton onClick={handleCancel} disabled={isWebUrlLoading}>
            {isWebUrlLoading && <StyledCircleSpinner lineWidth={2} />}
            Manage subscription
          </StyledLinkButton>
        )}
        {cancellableOnDev && (
          <StyledLinkButton onClick={handleCancel} disabled={isWebUrlLoading}>
            {isWebUrlLoading && <StyledCircleSpinner lineWidth={2} />} Manage
            subscription [DEV]
          </StyledLinkButton>
        )}
        <StyledLink
          href={TERMS_SUBS_REPLIKA_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          View subscription terms
        </StyledLink>
      </Actions>
      {subscription.source === 'stripe' ? <StripeBadge /> : null}
    </ActiveSubscriptionRoot>
  );
}

export default ActiveSubscription;

const ActiveSubscriptionRoot = styled.div`
  flex: 0 0 auto;
  height: 500px;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.fgColor};

  ${dialogMobileMedia`
    flex: 1 0 auto;
    height: auto;
    width: 100%;
    max-width: 100vw;
  `}
`;

const SubscriptionCardPlaceholder = styled.div`
  margin: 30px 0 10px;
  height: 130px;
`;

const Title = styled.h3`
  font-size: 28px;
  line-height: 32px;
  margin: 0 0 20px;
  width: 100%;
  text-align: center;

  ${dialogMobileMedia`
    margin-top: 50px;
  `}
`;

const Description = styled.p`
  width: 100%;
  margin: 0 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-family: ${(p) => p.theme.fonts.display};
`;

const StyledCircleSpinner = styled(CircleSpinner)`
  width: 14px;
  height: 14px;
`;

const StyledLinkButton = styled(LinkButton)`
  color: ${(p) => p.theme.dimmedFgColor};
  text-decoration: none;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;

  &:focus-visible:focus {
    border-radius: 4px;
    ${outlineCss({ offset: '2px' })}
  }
`;

const StyledLink = styled.a`
  color: ${(p) => p.theme.dimmedFgColor};

  &:focus-visible:focus {
    border-radius: 4px;
    ${outlineCss({ offset: '2px' })}
  }
`;

const Actions = styled.div`
  margin: 30px 0 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  text-align: center;

  & > * ~ * {
    margin-left: 32px;

    ${dialogMobileMedia`
      margin-left: 24px;
    `}
  }

  ${dialogMobileMedia`
    margin-top: 20px;
  `}
`;
